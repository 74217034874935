import React from "react"
import { mockProjects } from "../../mocks"
import { StaticImage } from "gatsby-plugin-image"
import { Picture } from "../../components"
import { FrontPageTemplateProps } from "../../templates"

export const fadeCarousel: FrontPageTemplateProps["fadeCarousel"] = {
  items: mockProjects.map(project => ({
    id: project.title,
    element: (
      <Picture
        key={project.title}
        content={{
          subline: project.year,
          headline: project.title,
          description: project.description,
        }}
        image={<img src={project.imgUrl} alt={project.title} />}
      />
    ),
  })),
}
