import React from "react"
import { FrontPageTemplateProps } from "../../templates"
import { PageTransitionLink } from "../../gatsby-components"

export const jobofferTeaser: FrontPageTemplateProps["jobofferTeaser"] = {
  content: (
    <>
      <h2 className="animate-fade-in-up">Werde Teil unseres Teams</h2>
      <p>
        Du bist motiviert, teamfähig, flexibel und belastbar!
        Dann freuen wir uns auf deine Bewerbung mit aussagekräftigem Portfolio und Lebenslauf!
      </p>
    </>
  ),
  link: <a href="/bochem-schmidt_werde-teil-unseres-teams.pdf" target="_blank" download>Zum Jobangebot</a>,
}
