import React from "react"
import { graphql, type PageProps } from "gatsby"
import { FrontPageTemplate } from "../templates"
import { Seo } from "../gatsby-components"
import { sanityProjectsToFadeCarousel } from "../gatsby-helper"
import * as tempContent from "../temp-content/frontPage"
import { layout as layoutTempContent } from "../temp-content/layout"
import { GatsbySanityProject } from "../types"

const IndexPage = ({ data }: IndexPageProps) => {
  // Destructure Data
  const gatsbySanityProjects = data.allSanityProject.nodes

  // Sanitize projects to fadeCarouselItems
  const fadeCarouselItems = sanityProjectsToFadeCarousel(gatsbySanityProjects)
  return (
    <>
      <Seo title="Home" />
      <FrontPageTemplate
        layout={layoutTempContent}
        fadeCarousel={{ items: fadeCarouselItems }}
        jobofferTeaser={tempContent.jobofferTeaser}
        teamTeaser={tempContent.teamTeaser}
        contactTeaser={tempContent.contactTeaser}
      />
    </>
  )
}

export type IndexPageProps = PageProps<{
  allSanityProject: {
    nodes: GatsbySanityProject[]
  }
}>

export const query = graphql`
  query FrontPageQuery {
    allSanityProject(sort: { fields: orderRank, order: ASC }) {
      nodes {
        id
        name
        scope
        titleImage {
          asset {
            gatsbyImageData(backgroundColor: "#000", placeholder: NONE)
          }
        }
        slug {
          current
        }
        year
      }
    }
  }
`

export default IndexPage
