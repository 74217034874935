import React from "react"
import { FrontPageTemplateProps } from "../../templates"
import { PageTransitionLink } from "../../gatsby-components"

export const contactTeaser: FrontPageTemplateProps["contactTeaser"] = {
  content: (
    <>
      <h2 className="animate-fade-in-up">Starten Sie Ihr Projekt mit uns</h2>
      <p>
        Lernen Sie uns und unsere Arbeitsweise in einem unverbindlichen Gespräch
        kennen – gerne bei Ihnen vor Ort. Schreiben Sie uns oder rufen Sie uns
        an.
      </p>
    </>
  ),
  link: <PageTransitionLink to="kontakt">Kontakt</PageTransitionLink>,
}
