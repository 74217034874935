import React from "react"
import { FrontPageTemplateProps } from "../../templates"
import { PageTransitionLink } from "../../gatsby-components"

export const teamTeaser: FrontPageTemplateProps["teamTeaser"] = {
  content: (
    <>
      <h2 className="animate-fade-in-up">Dem Ort Identität geben</h2>
      <p>
        Das Architekturbüro Bochem.Schmidt wurde 2014 gegründet. Die Gründer
        Sebastian Bochem und Ingo Schmidt verbindet die Überzeugung, dass jedes
        Gebäude, das den Menschen in den Mittelpunkt setzt, gestalterisch
        begeistern kann und dabei seine Funktionsfähigkeit sogar erhöht. Denn
        Kreatives Herangehen an das Gebäude bindet den Menschen, der es später
        benutzen soll, viel besser in seine zukünftige (Arbeits)Umgebung ein. Zu
        den Kunden von Bochem.Schmidt zählen Unternehmen aus der Industrie, dazu
        die öffentliche Hand, Investoren sowie verschiedene private Kunden.
        Gemeinsam mit ihrem Team schaffen es die Inhaber, nahe an Ihren Kunden
        zu denken und zu handeln und dadurch ein Objekt zu realisieren, das
        funktionale Erwartungen mindestens erfüllt und gestalterische
        übertrifft. So entsteht Vertrauen, das eine solide Basis ist für
        zukünftiges Schaffen. Und nur wenn man die Vergangenheit eines Ortes –
        oder eines Gebäudes – versteht und erkennt, kann man auch dessen Zukunft
        gestalten – und diesem Ort Identität verleihen.
      </p>
    </>
  ),
  link: <PageTransitionLink to="/team">Zum Team</PageTransitionLink>,
}
