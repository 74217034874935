export const mockProjects = [
  {
    year: "2020",
    title: "First Project",
    description: "Lorem ipsum dolor sit amet.",
    imgUrl: "https://picsum.photos/id/1048/1280/720",
  },
  {
    year: "2021",
    title: "Second Project",
    description: "Lorem ipsum dolor sit amet.",
    imgUrl: "https://picsum.photos/id/1047/1280/720",
  },
  {
    year: "2022",
    title: "Third Project",
    description: "Lorem ipsum dolor sit amet.",
    imgUrl: "https://picsum.photos/id/1040/1280/720",
  },
  {
    year: "2020",
    title: "Forth Project",
    description: "Lorem ipsum dolor sit amet.",
    imgUrl: "https://picsum.photos/id/1033/1280/720",
  },
  {
    year: "2019",
    title: "Fifth Project",
    description: "Lorem ipsum dolor sit amet.",
    imgUrl: "https://picsum.photos/id/1031/1280/720",
  },
  {
    year: "2018",
    title: "Sixth Project",
    description: "Lorem ipsum dolor sit amet.",
    imgUrl: "https://picsum.photos/id/1029/1280/720",
  },
]
